import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6af8e56e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_banner = _resolveComponent("home-banner")!
  const _component_google_map = _resolveComponent("google-map")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_home_banner, {
      id: "banner",
      "file-name": $options.backgroundPhoto
    }, null, 8, ["file-name"]),
    _createElementVNode("div", {
      id: "map-container",
      class: _normalizeClass({'point-details-visible': $setup.isShort})
    }, [
      _createVNode(_component_google_map, { "my-location": $setup.myLocation }, null, 8, ["my-location"])
    ], 2),
    _createElementVNode("div", {
      id: "map-footer",
      style: _normalizeStyle({ backgroundColor: $options.backgroundColor })
    }, [
      _createVNode(_component_ion_button, {
        color: $setup.myLocation ? 'primary' : 'light',
        size: "small",
        onClick: $setup.toggleMyLocation
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            src: "assets/icon/locate-outline.svg",
            slot: "icon-only"
          })
        ]),
        _: 1
      }, 8, ["color", "onClick"])
    ], 4)
  ]))
}