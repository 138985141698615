<template>
  <div :style="{ backgroundImage: getBackgroundImageValue(fileName) }" />
</template>

<script>
const getBackgroundImageValue = fileName => {
  return fileName ? `url('https://pointsmap.com/images/uploads/${fileName}')` : 'none'
}

export default {
  name: "HomeBanner.vue",
  props: {
    fileName: String,
  },
  setup() {
    return {
      getBackgroundImageValue
    }
  },
}
</script>

<style scoped>
div {
  background-color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: #111;
  height: 100%;
  width: 100%;
}
</style>