
import HomeBanner from '@/components/HomeBanner.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import store from '@/store'
import { Plugins } from '@capacitor/core'
import {
  IonButton,
  IonIcon,
} from "@ionic/vue"
import {ref, watch} from "vue";
import {useRoute} from "vue-router";

const pwaConfig = ref<any>({})
const isShort = ref<any>(false)
const myLocation = ref<any>(undefined)
let myLocationWatchId = ''
let mapContainer = undefined as any

const stopWatchingMyLocation = () => {
  if (myLocationWatchId) {
    Plugins.Geolocation.clearWatch({ id: myLocationWatchId })
    myLocationWatchId = ''
  }
  myLocation.value = undefined
}

const watchMyLocation = async () => {
  myLocationWatchId = Plugins.Geolocation.watchPosition({}, (position, err) => {
    if (err) {
      console.error('Unable to watch your location:', err)
      stopWatchingMyLocation()
      alert(
        "If you want to see where you are on the map, please allow this website / app to know your device's location. "
        + "You can try closing your browser and re-opening it, but if you see this again, you may need to adjust your browser's settings."
      )
    } else {
      myLocation.value = {
        accuracy: position.coords.accuracy,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    }
  })
}

const toggleMyLocation = () => {
  if (myLocationWatchId) {
    stopWatchingMyLocation()
  } else {
    watchMyLocation()
  }
}

export default {
  name: 'MapLayout',
  components: {
    GoogleMap,
    HomeBanner,
    IonButton,
    IonIcon,
  },
  mounted() {
    mapContainer = document.getElementById('map-container')
  },
  setup() {
    store.getPwaConfig().then(data => pwaConfig.value = data)

    const route = useRoute()
    watch(
        () => route.params.pointGuid,
        () => {
          if (mapContainer) {
            if (route.params.pointGuid) {
              isShort.value = true
            } else {
              isShort.value = false
            }
          }
        }
    )

    return {
      isShort,
      myLocation,
      toggleMyLocation,
    }
  },
  computed: {
    backgroundColor(): string {
      return '#' + pwaConfig?.value?.color
    },
    backgroundPhoto(): Promise<string> {
      return pwaConfig.value.pwa_background_photo || ''
    },
  },
}
