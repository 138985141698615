
import store from '@/store'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import HomeMapLayout from '@/layouts/home/MapLayout.vue'
import jsQR from "jsqr";
import {defineComponent, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

const pwaConfig = ref<any>({})
const showSearchField = ref<any>(false)
const showQrScanner = ref<any>(false)

let qrCanvasContext: CanvasRenderingContext2D | undefined
let qrCanvasElement: HTMLCanvasElement | undefined
let qrFileInputElement: HTMLInputElement | undefined
let qrVideoElement: HTMLVideoElement | undefined
let qrVideoStream: MediaStream | undefined

const offerNavigationToQrCodeUrl = (qrCodeData: any) => {
  if (String(qrCodeData).startsWith('http')) {
    if (confirm(`Would you like to go to that URL?\n${qrCodeData}`)) {
      window.location = qrCodeData
    }
  } else {
    console.log(qrCodeData)
    alert('That QR code did not have a URL in it')
  }
}

let actOnQrCodeData = offerNavigationToQrCodeUrl

const getQrCodeFromCanvas = (
    canvasContext: CanvasRenderingContext2D,
    canvasElement: HTMLCanvasElement
) => {
  const imageData = canvasContext.getImageData(
      0,
      0,
      canvasElement.width,
      canvasElement.height
  )
  return jsQR(imageData.data, imageData.width, imageData.height)
}

const turnOffQrScanner = () => {
  showQrScanner.value = false
  qrVideoStream?.getVideoTracks().forEach(videoTrack => videoTrack.stop())
  qrVideoStream = undefined
}

const scanVideoFrameForQrCode = () => {
  console.log('scanVideoFrameForQrCode') // TEMP
  if (!showQrScanner.value) {
    return
  }

  if (!qrCanvasElement) {
    console.error('No QR code canvas element available')
    return
  } else if (!qrCanvasContext) {
    console.error('No QR code canvas context available')
    return
  } else if (!qrVideoElement) {
    console.error('No QR code video element available')
    return
  }

  if (qrVideoElement.readyState === qrVideoElement.HAVE_ENOUGH_DATA) {
    console.log('HAVE_ENOUGH_DATA') // TEMP
    qrCanvasElement.width = qrVideoElement.clientWidth
    qrCanvasElement.height = qrVideoElement.clientHeight

    qrCanvasContext.drawImage(
        qrVideoElement,
        0,
        0,
        qrCanvasElement.width,
        qrCanvasElement.height
    )

    const code = getQrCodeFromCanvas(qrCanvasContext, qrCanvasElement)
    console.log('code:', code) // TEMP
    if (code) {
      turnOffQrScanner()
      actOnQrCodeData(code.data)
    } else {
      requestAnimationFrame(scanVideoFrameForQrCode)
    }
  } else {
    console.log('! HAVE_ENOUGH_DATA') // TEMP
    requestAnimationFrame(scanVideoFrameForQrCode)
  }
}

const turnOnQrScanner = async (returnQrCodeData: (qrCodeData: any) => void) => {
  actOnQrCodeData = returnQrCodeData
  try {
    qrVideoStream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' }
    })
    if (qrVideoElement) {
      qrVideoElement.srcObject = qrVideoStream
      qrVideoElement.play()
      requestAnimationFrame(scanVideoFrameForQrCode)
    } else {
      console.error('Could not find the QR code video element on the page')
    }
  } catch (error: any) {
    turnOffQrScanner()
    console.error('Unable to get video stream from camera', error)
    qrFileInputElement?.click() // If the camera didn't work, try the file input.
  }
}

export default defineComponent({
  name: 'Home',
  components: {
    HomeMapLayout,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  computed: {
    insideSubmap(): boolean {
      return !!store.state.selectedSubmap
    },
  },
  mounted() {
    qrVideoElement = document.getElementById('qr-scanner-video') as HTMLVideoElement || undefined
    qrFileInputElement = document.getElementById('qr-scanner-file-input') as HTMLInputElement || undefined
    qrCanvasElement = document.getElementById('qr-scanner-canvas') as HTMLCanvasElement || undefined
    if (qrCanvasElement) {
      qrCanvasContext = qrCanvasElement.getContext('2d') || undefined
    }
  },
  props: {
    queryStringPointGuid: String,
  },
  setup(props) {
    const router = useRouter()

    store.getPwaConfig().then(async config => {
      pwaConfig.value = config

      // If there is a point GUID in the query string (?pid=), redirect to that point.
      if (props.queryStringPointGuid) {
        const point = await store.getPointOnceLoaded(props.queryStringPointGuid)
        if (point.parentSubmap) {
          router.push({ name: 'point-in-submap', params: {
            pointGuid: props.queryStringPointGuid,
            submapGuid: point.parentSubmap,
          } })
        } else {
          router.push({ name: 'point', params: {
            pointGuid: props.queryStringPointGuid
          } })
        }
      }
    })

    const toggleSearchField = () => {
      showSearchField.value = !showSearchField.value
      if (showSearchField.value) {
        setTimeout(() => {
          const searchField = document.getElementById('search-field') as HTMLInputElement
          searchField && searchField.focus()
        })
      } else {
        if (router.currentRoute.value.name === 'search') {
          router.back()
          setTimeout(() => {
            if (router.currentRoute.value.name === 'search') {
              router.push({ name: 'home'})
            }
          })
        }
      }
    }

    const doSearch = (event: any) => {
      event.preventDefault()
      const searchField = document.getElementById('search-field') as HTMLInputElement
      const searchString = searchField.value
      if (searchString) {
        router.push({ name: 'search', params: { searchString } })
        searchField.blur()
      }
    }

    const handleQrFileInput = (event: any) => {
      const files: FileList = event.target?.files || []
      const file = files.item(0)
      if (file) {
        const img = new Image();
        img.onload = () => {
          if (!qrCanvasContext || !qrCanvasElement) {
            console.error('Missing QR canvas context or element')
            return
          }
          qrCanvasContext.drawImage(
              img,
              0,
              0,
              qrCanvasElement.width,
              qrCanvasElement.height
          )

          const code = getQrCodeFromCanvas(qrCanvasContext, qrCanvasElement)
          console.log('code:', code) // TEMP
          if (code) {
            turnOffQrScanner()
            actOnQrCodeData(code.data)
          } else {
            alert('We did not find a QR code in that picture. Try again?')
          }
        }
        img.src = URL.createObjectURL(file)
      }
    }

    const toggleQrCodeScanner = (event: any) => {
      event.preventDefault()
      showQrScanner.value = !showQrScanner.value
      if (showQrScanner.value) {
        setTimeout(() => turnOnQrScanner(offerNavigationToQrCodeUrl))
      } else {
        turnOffQrScanner()
      }
    }

    const route = useRoute()
    const initializeSearchField = () => {
      showSearchField.value = (route.name === 'search')
      if (showSearchField.value) {
        setTimeout(() => {
          const searchField = document.getElementById('search-field') as HTMLInputElement
          searchField && (searchField.value = String(route.params.searchString || ''))
        })
      }
    }
    initializeSearchField()
    watch(
        () => route.name,
        async () => {
          initializeSearchField()
        }
    )

    const exitSubmap = () => {
      const submapGuid = store.state.selectedSubmap
      const submap = store.getSubmap(submapGuid)
      if (submap) {
        const pointGuid = submap.parentPointGuid
        router.push({ name: 'point', params: { pointGuid } })
      }
    }

    return {
      doSearch,
      exitSubmap,
      handleQrFileInput,
      showQrScanner,
      showSearchField,
      toggleQrCodeScanner,
      toggleSearchField,
    }
  }
})
