
/*global google*/
import environment from '@/environments/environment'
import { isPointSelected, inSelectedFolder } from '@/store/points'
import { Loader } from "@googlemaps/js-api-loader"
import make from '@/helpers/make'
import router from '@/router'
import store from '@/store'
import { getIconForFolder } from '@/store/folders'
import {nextTick, ref, watch} from 'vue';
import {useRoute} from "vue-router";

const getImgSrcFor = (accountCodeName: string, point: any) => {
  if (point.photos.length < 1) {
    return undefined
  }
  const imgSrcBaseUrl = `https://pointsmap.com/${accountCodeName}/data/photos/`
  return imgSrcBaseUrl + point.photos[0]
}

const isOnGoogleMap = (point: any) => point.parentSubmap === undefined

let infoWindow: any
let mapElement: any
let myLocationDot: any
const accountCodeName = ref<string>('')
const clickedPoint = ref<any>({})
const markersMeta: MarkerMeta[] = []
const markerSizeSelected = { width: 40, height: 36 }
const markerSizeUnselected = { width: 20, height: 18 }

const hideLine = (line: google.maps.Polyline) => {
  line.setVisible(false)
}

const hideMarker = (marker: google.maps.Marker) => {
  marker.setMap(null)
}

const hideZone = (zone: google.maps.Polygon) => {
  zone.setVisible(false)
}

const showLine = (line: google.maps.Polyline) => {
  line.setVisible(true)
}

const showZone = (zone: google.maps.Polygon) => {
  zone.setVisible(true)
}

const showMarkerAsSelected = (marker: google.maps.Marker, map: google.maps.Map) => {
  const icon: any = marker.getIcon()
  if (icon) {
    icon.scaledSize = markerSizeSelected
    icon.size = markerSizeSelected
    marker.setIcon(icon)
  }
  marker.setZIndex(+google.maps.Marker.MAX_ZINDEX + 1)
  marker.setMap(map)
}

const showMarkerAsUnselected = (marker: google.maps.Marker, map: google.maps.Map) => {
  const icon: any = marker.getIcon()
  if (icon) {
    icon.scaledSize = markerSizeUnselected
    icon.size = markerSizeUnselected
    marker.setIcon(icon)
  }
  const markerZIndex = marker.getZIndex() || 0
  if (markerZIndex > +google.maps.Marker.MAX_ZINDEX) {
    marker.setZIndex(+google.maps.Marker.MAX_ZINDEX)
  }
  marker.setMap(map)
}

const getMarkerMetaForPoint = (pointGuid: string) => {
  return markersMeta.find((markerMeta: any) => markerMeta.pointGuid === pointGuid)
}

const goToPointDetailsRoute = (pointGuid: any) => {
  const folderGuid = store.state.selectedFolder
  if (folderGuid) {
    router.push({ name: 'point-in-folder', params: { pointGuid, folderGuid } })
  } else {
    router.push({ name: 'point', params: { pointGuid } })
  }
}

const hideInfoWindow = () => {
  if (infoWindow !== undefined) {
    infoWindow.close()
  }
}

/* This method is needed because the Info Window element is moved into the
 * Google map DOM, then deleted when closed. Using a Vue template only works the
 * first time, and cloning an assembled Vue template seems to lose the on-click
 * calls. */
const assembleInfoWindowForPoint = (point: any) => {
  const showPointDetails = () => {
    hideInfoWindow()
    goToPointDetailsRoute(point.guid)
  }

  return make('div', { id: 'info-window' }, [
    make('strong', { style: 'font-size: larger' }, [
      make('a', { href: 'javascript:void(0)' }, [point.label], showPointDetails)
    ]),
    make('div', {}, [
      point.photos?.length && make(
        'img',
        {
          alt: point.label,
          src: getImgSrcFor(accountCodeName.value, point),
          style: 'width: 100%; height: auto;',
        },
        [],
        showPointDetails
      )
    ]),
    make('div', {}, [
      make('ion-button', { color: 'primary', fill: 'outline' }, [
        'Show'
      ], showPointDetails),
      make('ion-button', { color: 'primary', fill: 'outline' }, [
        make('a', {
          href: `http://maps.google.com/?q=${point.lat},${point.lng}`,
          target: '_blank',
        }, [
          'Go'
        ])
      ]),
    ])
  ])
}

const showInfoWindowForPoint = async (point: any) => {
  if (infoWindow === undefined) {
    infoWindow = new google.maps.InfoWindow()
  }
  infoWindow.setOptions({
    content: assembleInfoWindowForPoint(point),
  })
  const markerMeta = getMarkerMetaForPoint(point.guid)
  if (markerMeta !== undefined) {
    infoWindow.open({
      anchor: markerMeta.marker,
      map: store.getGoogleMap(),
      shouldFocus: true,
    } as any)
    if (markerMeta.line) {
      showLine(markerMeta.line)
    } else if (markerMeta.zone) {
      showZone(markerMeta.zone)
    }
  }
}

const showPointsInFolder = (selectedFolderGuid: string|undefined) => {
  const selectedFolder = store.getFolder(selectedFolderGuid)
  const googleMap = store.getGoogleMap()
  const bounds = new google.maps.LatLngBounds()
  for (const { line, marker, pointGuid, zone } of markersMeta) {
    const markerPosition = marker.getPosition()
    if (selectedFolder) {
      if (store.isPointInFolder(pointGuid, selectedFolder)) {
        if (line) {
          line.getPath().forEach(
              (latLng: google.maps.LatLng) => bounds.extend(latLng)
          );
          showLine(line)
        } else if (zone) {
          if (markerPosition) {
            bounds.extend(markerPosition);
          }
          showZone(zone)
        } else {
          if (markerPosition) {
            bounds.extend(markerPosition);
          }
          showMarkerAsSelected(marker, googleMap)
        }
      } else {
        marker && hideMarker(marker)
        line && hideLine(line)
        zone && hideZone(zone)
      }
    } else {
      if (line) {
        line.getPath().forEach(
          (latLng: google.maps.LatLng) => bounds.extend(latLng)
        );
        showLine(line)
      } else if (zone) {
        if (markerPosition) {
          bounds.extend(markerPosition);
        }
        showZone(zone)
      } else {
        if (markerPosition) {
          bounds.extend(markerPosition);
        }
        showMarkerAsUnselected(marker, googleMap)
      }
    }
  }
  if (selectedFolder) {
    googleMap.fitBounds(bounds, 24)
  } else {
    store.getPwaConfig().then((pwaConfig: any) => {
      const lat = +(pwaConfig.init_start_lat || 0)
      const lng = +(pwaConfig.init_start_long || 0)
      const zoom = +(pwaConfig.init_zoom_lvl || 8)
      googleMap.setCenter({ lat, lng })
      googleMap.setZoom(zoom)
    })
  }
}

const hasGoogleLibraryLoaded = () => (typeof google !== 'undefined')

const showPoints = async (points: any) => {
  if (!hasGoogleLibraryLoaded()) {
    return
  }
  const map = store.getGoogleMap()
  points.filter(isOnGoogleMap).forEach((point: any) => {
    if (point.group) {
      return // Don't add markers for points in a group
    }
    const folderIcon = getIconForFolder(point.folder)
    const iconToUse = point.icon ? point.icon : folderIcon
    let markerIcon: any = undefined
    if (iconToUse) {
      markerIcon = {
        scaledSize: isPointSelected(point) ? markerSizeSelected : markerSizeUnselected,
        size: isPointSelected(point) ? markerSizeSelected : markerSizeUnselected,
        url: `https://pointsmap.com/images/icons/points/${iconToUse}`,
      }
    }
    const marker = new google.maps.Marker({
      icon: markerIcon,
      map: inSelectedFolder(point) || !store.state.selectedFolder ? map : null,
      position: { lat: point.lat, lng: point.lng },
      title: point.label,
    })
    marker.addListener("click", () => {
      if (clickedPoint.value?.guid === point.guid) {
        showInfoWindowForPoint(point)
      } else {
        clickedPoint.value = point
      }
    })
    const markerMeta = {
      marker,
      pointGuid: point.guid,
      folderGuid: point.folder,
    } as MarkerMeta;
    if (point.latLngLine !== undefined) {
      const line = new google.maps.Polyline({
        map,
        path: point.latLngLine.path,
        strokeColor: point.latLngLine.strokeColor,
        strokeOpacity: point.latLngLine.strokeOpacity,
        strokeWeight: point.latLngLine.strokeWeight,
        visible: inSelectedFolder(point) || !store.state.selectedFolder,
      })
      line.addListener("click", () => {
        if (clickedPoint.value?.guid === point.guid) {
          showInfoWindowForPoint(point)
        } else {
          clickedPoint.value = point
        }
      })
      markerMeta.line = line
    } else if (point.latLngZone !== undefined) {
      const zone = new google.maps.Polygon({
        fillColor: point.latLngZone.fillColor,
        fillOpacity: point.latLngZone.fillOpacity,
        map,
        paths: point.latLngZone.path,
        strokeColor: point.latLngZone.strokeColor,
        strokeOpacity: point.latLngZone.strokeOpacity,
        strokeWeight: point.latLngZone.strokeWeight,
        visible: inSelectedFolder(point) || !store.state.selectedFolder,
      })
      zone.addListener("click", () => {
        if (clickedPoint.value?.guid === point.guid) {
          showInfoWindowForPoint(point)
        } else {
          clickedPoint.value = point
        }
      })
      markerMeta.zone = zone
    }
    markersMeta.push(markerMeta)
  })
}

const showMyLocation = (location: any) => {
  const map = store.getGoogleMap()
  if (myLocationDot === undefined) {
    myLocationDot = new google.maps.Circle({
      clickable: false,
      fillColor: '#6666ff',
      map,
      radius: location.accuracy,
      strokePosition: google.maps.StrokePosition.OUTSIDE,
      strokeColor: '#3333cc',
      strokeOpacity: 1.0,
      strokeWeight: 4,
      visible: false,
    })
  }
  if (location === undefined) {
    myLocationDot.setVisible(false)
  } else {
    myLocationDot.setCenter({ lat: location.lat, lng: location.lng })
    myLocationDot.setVisible(true)
    map.panTo({ lat: location.lat, lng: location.lng })
  }
}

const resizePointsOnMap = () => {
  const map = store.getGoogleMap()
  for (const { marker, pointGuid } of markersMeta) {
    const point = store.getPoint(pointGuid)
    if (inSelectedFolder(point) || !store.state.selectedFolder) {
      if (isPointSelected(point)) {
        showMarkerAsSelected(marker, map)
      } else {
        showMarkerAsUnselected(marker, map)
      }
    } else {
      hideMarker(marker)
    }
  }
}

export default {
  name: "GoogleMap",
  data() {
    return {
      accountCodeName,
      clickedPoint,
      store,
    }
  },
  methods: {
    getImgSrcFor,
    openPoint(pointGuid: any) {
      console.log('openPoint(', pointGuid, ')')
    }
  },
  props: {
    myLocation: Object,
  },
  watch: {
    myLocation (location: any) {
      showMyLocation(location)
    },
    'store.state.selectedFolder': {
      handler(folderGuid: any) {
        showPointsInFolder(folderGuid)
      }
    },
    'store.state.pointsByGuid': {
      handler(pointsByGuid: Record<string,any>) {
        showPoints(Object.values(pointsByGuid))
        if (store.state.selectedFolder) {
          showPointsInFolder(store.state.selectedFolder)
        }
      },
      deep: true
    },
    clickedPoint: {
      handler(newPoint: any) {
        // Give Vue time to finish updating the DOM, then...
        nextTick(() => {
          showInfoWindowForPoint(newPoint)
        })
      }
    },
  },
  mounted() {
    const placeholder = document.getElementById("google-map-placeholder") as HTMLElement
    if (store.state.googleMapElement) {
      placeholder.replaceWith(store.state.googleMapElement)
    } else {
      mapElement = document.createElement('div')
      mapElement.id = 'google-map'
      mapElement.style.height = '100%'
      placeholder.replaceWith(mapElement)

      const loader = new Loader({
        apiKey: environment.googleMapsApiKey,
        libraries: ['geometry'],
        version: "quarterly",
      });

      store.getPwaConfig().then((pwaConfig: any) => {
        const lat = +(pwaConfig.init_start_lat || 0)
        const lng = +(pwaConfig.init_start_long || 0)
        const zoom = +(pwaConfig.init_zoom_lvl || 8)

        loader.load().then(() => {
          const map = new google.maps.Map(mapElement as HTMLElement, {
            center: {lat, lng},
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
            styles: [
              {
                elementType: 'labels',
                featureType: 'poi',
                stylers: [
                  { visibility: 'off' },
                ],
              }
            ],
            zoom,
          })
          store.setGoogleMap(map, mapElement)
          setTimeout(() => {
            showPoints(Object.values(store.state.pointsByGuid))
          })
        });

        accountCodeName.value = store.state.accountCodeName
      })
    }
  },
  setup() {
    const route = useRoute()
    watch(
      () => route.params.pointGuid,
      () => {
        if (mapElement && typeof google !== 'undefined') {
          google.maps.event.trigger(mapElement, 'resize');
          if (route.params.pointGuid) {
            const point = store.getPoint(route.params.pointGuid) as any
            const googleMap = store.getGoogleMap()
            if (point && googleMap) {
              googleMap.panTo({ lat: point.lat, lng: point.lng })
            }
          }
          resizePointsOnMap()
        }
      }
    )
  },
}
