
export default (
    tagName: string,
    attributes: any,
    children: Array<any>,
    onClick: any = undefined
) => {
    const element = document.createElement(tagName)
    for (const attributeName in attributes) {
        element.setAttribute(attributeName, attributes[attributeName])
    }
    for (const child of children) {
        if (child) {
            element.append(child)
        }
    }
    if (typeof onClick === 'function') {
        element.addEventListener('click', onClick)
    }
    return element
}
